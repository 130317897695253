<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <div class="card-body">
          <div class="row form-group justify-content-end">
            <div class="form-group col-md-5">
              <label class="col-md-12">Paciente :</label>
              <input required type="text" class="form-control" v-model="filtro.paciente" @keyup.enter="pesquisar()" />
            </div>
            <div class="form-group col-md-3">
              <label class="col-md-12">{{ $t("DATAINICIO.NOME") }}</label>
              <input required type="date" class="form-control" v-model="filtro.data_inicio" />
            </div>
            <div class="form-group col-md-3">
              <label class="col-md-12">{{ $t("DATAFINAL.NOME") }}</label>
              <input required type="date" class="form-control" v-model="filtro.data_fim" />
            </div>
            <div class="col-md-1 mt-8"> 
              <div class="">
                <button class="btn btn-success" @click="listar_receitas()">
                  <i class="fa fa-search search-icon m-0 p-0"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-11 pt-3">
      <div class="card full-height bg-ligth">
        <div class="card-body">
          <b-table :fields="[
            'created_at',
            'paciente',
            'data_nascimento',
            'C.I',
            'medico',
            'descricao',
            'acoes',
          ]" :items="lista_receitas" :per-page="perPage" :current-page="currentPage" id="saida_produto" class="
                  table
                  table-responsive
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-borderless
                " show-empty empty-text="Nenhum registro encontrado!">
            <template #head(created_at)="{ item }"> FECHA DE PRESCRIPCIÓN</template>
            <template #head(descricao)="{ item }"> DESCRICIÓN</template>
            <template #head(data_nascimento)="{ item }"> Fecha nascimiento</template>
            <template #cell(created_at)="{ item }"> {{ formatarData(item.created_at) }}</template>
            <template #head(acoes)>Acciones</template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button v-show="lista_permissoes_filial.u_Sala" @click="visualizar(item)"
                  class="btn btn-icon btn-light mx-1" v-b-tooltip.hover title="Ver itens de prescripciones">
                  <i class="far fa-eye text-primary"></i>
                </button>
                <button v-show="lista_permissoes_filial.u_Sala" @click="pdf_ticket(item.ocorrencia_id)"
                  class="btn btn-icon btn-light mx-1" v-b-tooltip.hover title="Imprimir Salidas hechas">
                  <i class="fas fa-print text-info"></i>
                </button>


              </div>
            </template>
            <template #cell(tipo)="{ item }"><span></span>
              <span style="font-size: 12px" class="label label-lg label-inline" :class="{
                'label-light-success': item.tipo === 1,
                'label-light-info': item.tipo === 2,
                'label-light-warning': item.tipo === 3,
                'label-light-primary': item.tipo === 4,
              }">
                {{
                  item.tipo === 1
                    ? "Paciente"
                    : item.tipo === 2
                      ? "Vencido"
                      : item.tipo === 3
                        ? "Danificado"
                        : item.tipo === 4
                          ? "Donacion"
                          : item.tipo === 5
                            ? "Estoque Retroativo "
                            : "Saida unica"
                }}
              </span>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="lista_receitas.length" :per-page="perPage"
            aria-controls="saida_produto">
          </b-pagination>
        </div>
      </div>
    </div>
    <b-modal v-model="modal_ocorrencia.show" title="Prescripción" ref="Receita" size="xl" centered hide-footer>
      <b-overlay :show="modal_ocorrencia.loading" rounded="sm">
        <div class="row justify-content-center">
          <receita></receita>
        </div>
      </b-overlay>
    </b-modal>


  </div>
</template>

<script>
import Receita from "@/view/components/ocorrencia/modalReceitaFarmacia.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  components: { Receita }, mixins: { fireAlert },
  data() {
    return {
      currentPage: 2,
      perPage: 10,
      filtro: {
        data_inicio: null,
        data_fim: null,
        paciente: null
      },
      modal_ocorrencia: {
        show: false,
        loading: false
      },
      nome_pesquisa: null,


    };
  },
  watch: {
    nome_pesquisa() {
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prescripciones Medicas" }]);
  },
  async created() {
    this.filtro.data_inicio = this.obterDataAtual()
    await this.listar_receitas();
  },
  computed: {

    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
    lista_receitas() {
      return this.$store.state.ocorrencia.lista_receitas;
    },



  },
  methods: {
    obterDataAtual() {
      const dataAtual = new Date();
      const ano = dataAtual.getFullYear();
      const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Os meses são indexados a partir de 0
      const dia = String(dataAtual.getDate()).padStart(2, '0');
      return `${ano}-${mes}-${dia}`;
    },
    async visualizar(value) {
      this.modal_ocorrencia.loading = true
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configEmpresa/atualizar", value).finally(() => {
        this.modal_ocorrencia.show = true
        this.modal_ocorrencia.loading = false
        this.$store.dispatch("configEmpresa/MudarPreloader", false)
      });
    },
    formatarData(dataString) {
      // Cria um objeto Date a partir da string fornecida
      const data = new Date(dataString);

      // Extrai os componentes da data
      const dia = String(data.getDate()).padStart(2, '0');
      const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses são indexados a partir de 0
      const ano = data.getFullYear();
      const hora = String(data.getHours()).padStart(2, '0');
      const minutos = String(data.getMinutes()).padStart(2, '0');

      // Retorna a data formatada
      return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
    },
    // Exemplo de uso


    async listar_receitas() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("ocorrencia/listar_receitas", this.filtro)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false)
        });
    },
    async pdf_ticket(ocorrencia_id) {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("ocorrencia/pdf_ticket", ocorrencia_id)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false)
        });
    },

    async listar_requerimentos() {
      // this.filtro.filial_id = window.localStorage.getItem('ucpFilialId')
      // await this.$store.dispatch("configEmpresa/listar_filial_empresa");
      // await this.$store.dispatch("produto/listar_produtos");
      // await this.$store.dispatch("substancia/listar_substancias");
      // await this.$store.dispatch("tipoClasse/listar_tipos_classe");
      // await this.$store.dispatch("produto/listar_tipos_produto")
    },


    async confirm(value, item) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + value,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (value == "desfazer a saída") this.desfazer(item);
          }
        },
      });
    },

    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },

  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>